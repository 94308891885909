
(function($) {
  
  // Vars
  var $hero = $('.hero');

  // Function to move image to the background
  function imgToBG() {
    var img = $hero.find('img').first();
    if(img.length) {
      $hero.css({
        "background-image": "url(" + img.attr("src") + ")"
      });
      img.remove();
    }
  }

  // Run imgToBG() when dom is ready
  imgToBG();

})(jQ171);

// Fancybox for vid

jQuery(window).load(function() {
   jQuery('.hero__popup').fancybox({
    'autoScale': true,
    'height': 450,
    'overlayColor': '#000',
    'overlayOpacity': 0.8,
    'overlayShow': true,
    'width': 800
  });
});

