
$(function() {

  // Vars
  var $fullPage = $('#fullpage');
  var $section = $('.section');

  // Function to move image to the background
  function imgToBG() {
    if($section.length) {
      $section.each(function() { 
        var img = $(this).find('img').first();
        if(img.length) {
          $(this).css({
            "background-image": "url(" + img.attr("src") + ")"
          });
          img.remove();
        }
      });
    }
  }

  // Function to initialize and configure fullpage
  function initFullPage() {
    if($fullPage.length) {
      fullpage.initialize('#fullpage', {
        'anchors': ['section-1', 'section-2', 'section-3', 'section-4'],
        'menu': '.history-nav',
        afterLoad: function(anchorLink, index) {
          $('.history-nav__item').removeClass('past');
          $activeItem = $('.history-nav__items').find('.history-nav__item.active');
          if($activeItem.length) {
            $activeItem.prevAll().addClass('past');
          }
        }
      });
    }
  }

  // Run stuff when dom is loaded
  imgToBG();
  initFullPage();

});
