
/* ==========================================================================
   Clone nav stuff for use in condensed nav and open/close functionality
   ========================================================================== */

(function($) {
  
  // Vars
  var animationSpeed = 400;
  var activeModifier = '--active';
  var condensedNavOpenClass = 'condensed-nav-open';
  var condensedNavClass = 'condensed-nav';
  var pageWrapClass = 'page-wrap';
  var pageOverlayClass = 'page-overlay';
  var triggerClass = 'condensed-nav-trigger';
  var $condensedNav = $('.' + condensedNavClass);
  var $pageOverlay = $('.' + pageOverlayClass);
  var $pageWrap = $('.' + pageWrapClass);
  var $primaryNav = $('.primary-nav');
  var $socialNav = $('.social-nav');
  var $trigger = $('.' + triggerClass);

  // Function to clone nav stuff and place it in the condensed nav
  function createCondensedNav() {
    if($primaryNav.length) {
      var $primaryNavClone = $primaryNav.clone();
      $condensedNav.append($primaryNavClone);
    }
    if($socialNav.length) {
      var $socialNavClone = $socialNav.clone();
      $condensedNav.append($socialNavClone);
    }
  }

  // Function to open menu
  function openMenu(trigger) {
    trigger.addClass(triggerClass + activeModifier);
    $condensedNav.addClass(condensedNavClass + activeModifier);
    $('body').addClass(condensedNavOpenClass);
  }

  // Function to close menu
  function closeMenu(trigger) {
    trigger.removeClass(triggerClass + activeModifier);
    $condensedNav.removeClass(condensedNavClass + activeModifier);
    $('body').removeClass(condensedNavOpenClass);
  }

  // Open/close menu on trigger click/touch
  $trigger.on('click', function(e) {
    e.preventDefault();
    var $trigger = $(this);
    if($trigger.hasClass(triggerClass + activeModifier))
      closeMenu($trigger);
    else
      openMenu($trigger);
  });

  // Function to add class and markup for condensed expand trigger */
  function addExpandTriggers() {
    $('.' + condensedNavClass + ' #mainnav > li').each(function() {
      if($(this).children('ul').size() >= 1) {
        $(this).append('<span class="expand-btn"></span>');
      }
    });
  }

  // Expand toggle for level 2
  $(window).load(function() {
    $('.expand-btn').on('click', function(e) {
      e.preventDefault();
      $(this).toggleClass('expand-btn--active');
      $(this).parent().find('ul').slideToggle(animationSpeed);
    });
  });

  // Run stuff after dom elements loaded
  createCondensedNav();
  addExpandTriggers();

})(jQ171);
