
(function($) {
  
  // Vars
  var $imgCard = $('.img-card');

  // Function to move image to the background
  function imgToBG() {
    if($imgCard.length) {
      $imgCard.each(function() { 
        var img = $(this).find('img').first();
        if(img.length) {
          $(this).css({
            "background-image": "url(" + img.attr("src") + ")"
          });
          img.remove();
        }
      });
    }
  }

  // Run imgToBG() when dom is ready
  imgToBG();

})(jQ171);
