
/* ==========================================================================
   Need to add some markup to style mainnav a bit easier
   ========================================================================== */

$(function() {
  
  // Vars
  var $menuLinks = $('.sw-menucode-item__link');

  // Function to add markup to make styling easier for mainnav
  function addMenuMarkup() {
    if($menuLinks.length) {
      $menuLinks.wrapInner('<span />');
    }
  }

  // Do addMenuMarkUp() on window load
  $(window).load(function() {
    addMenuMarkup();
  });

});

// Responsive Embed
$('section.default-main iframe[src*=youtube], section.default-main iframe[src*=vimeo]').each(function() {
  $(this).wrap('<div class="embed-container"></div>');
});

$(".bg-image-js").each(function() {
  var img = $(this).find("img");
  if (img.length) {
    $(this).css({"background-image": "url(" + img.attr("src") + ")"});
    img.remove();
  }
});
